import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "./axios/url";
import EncryptPayload from "../utils/EncryptPayload";
import DecryptPayload from "../utils/DecryptPayload";
import Authorization from "../utils/Authorization";
import Select from "react-select";
import "../login.css";

const options = [
  { value: "Owner", label: "Owner" },
  { value: "Staff", label: "Staff" },
];

const ForgotLoginPinNew = () => {
  const navigate = useNavigate();

  const [selectedOption, setSelectedOption] = useState(null);

  const [buttonState, setButtonState] = useState("forgotPin");

  const [customers, setCustomers] = useState([]);

  const [formData, setFormData] = useState({
    mobileNumber: "",
    userType: "",
    otp: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSelectChange = (option) => {
    setSelectedOption(option);
    setFormData({
      ...formData,
      userType: option ? option.value : "",
    });
  };

  const handleSubmitMobileNumber = async (e) => {
    e.preventDefault();

    const { mobileNumber, userType } = formData;
    const trimmedMobileNumber = mobileNumber.trim();

    const regex = /^[a-zA-Z0-9\s]*$/;
    const numericRegex = /^[0-9]*$/;

    if (!trimmedMobileNumber) {
      alert("Please Enter Mobile Number");
      return;
    }
    if (!userType) {
      alert("Please Select User Type");
      return;
    }
    if (trimmedMobileNumber.length !== 10) {
      alert("Mobile Number should be 10 digits");
      return;
    }
    if (!regex.test(trimmedMobileNumber)) {
      alert("Mobile Number should not contain special characters");
      return;
    }
    if (!numericRegex.test(trimmedMobileNumber)) {
      alert("Mobile Number should be in numbers");
      return;
    }
    const requestData = {
      type: "CustomerLoginForgotPinCheckRIC",
      mobile_number: formData.mobileNumber,
      user_type: formData.userType,
    };

    const requestDataString = JSON.stringify(requestData);

    const EncData = EncryptPayload(requestDataString);
    const PayloadEnc = EncData[0].payload.toString();
    const IvEnc = EncData[0].iv.toString();

    try {
      const checkResponse = await axios.post(
        "/custLoginForgotPinRIC.php",
        PayloadEnc,
        {
          headers: {
            "Content-Type": "text/plain",
            Authorization: Authorization,
            EncAuth: IvEnc,
          },
        }
      );

      const encAuthHeaderValue = checkResponse.headers["encauth"];
      const decryptedData = DecryptPayload(
        checkResponse.data,
        encAuthHeaderValue
      );

      const parsedData = JSON.parse(decryptedData);

      if (parsedData.status === "success") {
        setButtonState("otp");
        alert("OTP sent to registered mobile number");
        setFormData((prevFormData) => ({
          ...prevFormData,
          otp: "",
        }));
      } else if (parsedData.status === "error") {
        alert(parsedData.message);
        return;
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleSubmitOtp = async (e) => {
    e.preventDefault();
    const { otp } = formData;
    const trimmedOtp = otp.trim();
    const regex = /^[a-zA-Z0-9\s]*$/;
    const numericRegex = /^[0-9]*$/;

    if (!trimmedOtp) {
      alert("OTP should not be empty");
      return;
    }
    if (trimmedOtp.length !== 4) {
      alert("OTP should be 4 digits");
      return;
    }
    if (!regex.test(trimmedOtp)) {
      alert("OTP should not contain special characters");
      return;
    }
    if (!numericRegex.test(trimmedOtp)) {
      alert("OTP should be in numbers");
      return;
    }

    const requestData = {
      type: "CustomerForgotLoginPinRIC",
      mobile_number: formData.mobileNumber,
      otp: formData.otp,
      user_type: "Owner",
    };

    const requestDataString = JSON.stringify(requestData);

    const EncData = EncryptPayload(requestDataString);
    const PayloadEnc = EncData[0].payload.toString();
    const IvEnc = EncData[0].iv.toString();

    try {
      const verifyResponse = await axios.post(
        "/custLoginForgotPinRIC.php",
        PayloadEnc,
        {
          headers: {
            "Content-Type": "text/plain",
            Authorization: Authorization,
            EncAuth: IvEnc,
          },
        }
      );

      const encAuthHeaderValue = verifyResponse.headers["encauth"];
      const decryptedData = DecryptPayload(
        verifyResponse.data,
        encAuthHeaderValue
      );

      const parsedData = JSON.parse(decryptedData);

      if (parsedData.status === "success") {
        setCustomers(parsedData.data);
        setButtonState("resetPin");
      } else if (parsedData.status === "error") {
        alert(parsedData.message);
        return;
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleResetPin = async (customer_id, mobile_number) => {
    const requestData = {
      type: "CustomerNewPinTriggerRIC",
      customer_id: customer_id,
      mobile_number: mobile_number,
      user_type: "Owner",
    };

    const requestDataString = JSON.stringify(requestData);

    const EncData = EncryptPayload(requestDataString);
    const PayloadEnc = EncData[0].payload.toString();
    const IvEnc = EncData[0].iv.toString();

    try {
      const verifyResponse = await axios.post(
        "/custLoginForgotPinRIC.php",
        PayloadEnc,
        {
          headers: {
            "Content-Type": "text/plain",
            Authorization: Authorization,
            EncAuth: IvEnc,
          },
        }
      );

      const encAuthHeaderValue = verifyResponse.headers["encauth"];
      const decryptedData = DecryptPayload(
        verifyResponse.data,
        encAuthHeaderValue
      );

      const parsedData = JSON.parse(decryptedData);

      if (parsedData.status === "success") {
        alert(parsedData.message);
        navigate("/login");
      } else if (parsedData.status === "error") {
        alert(parsedData.message);
        return;
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  return (
    <>
      <nav
        className="navbar"
        style={{
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#00172d",
          height: "80px",
        }}
      >
        <div
          className="actions text-light"
          style={{ cursor: "pointer", fontSize: "30px" }}
        >
          RADIANT INSTA CREDIT
        </div>
      </nav>
      <div className="container">
        <form action="">
          <div className="row justify-content-md-center">
            <div className="col-xl-4 col-lg-5 col-md-6 col-sm-12">
              <div className="login-screen">
                <div className="login-box">
                  <Link to="" className="login-logo">
                    <img src="/img/logo.png" width="70" height="70" alt="" />
                  </Link>
                  {buttonState === "forgotPin" ? (
                    <h5
                      className="text-gray-900"
                      style={{ fontWeight: "bold" }}
                    >
                      Reset Your Login PIN
                    </h5>
                  ) : null}

                  {buttonState === "forgotPin" ? (
                    <>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Mobile Number"
                          id="mobileNumber"
                          name="mobileNumber"
                          value={formData.mobileNumber}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="form-group">
                        <Select
                          defaultValue={selectedOption}
                          onChange={handleSelectChange}
                          options={options}
                          placeholder="Select User Type"
                        />
                      </div>
                    </>
                  ) : buttonState === "otp" ? (
                    <div className="form-group">
                      <p className="font-weight-bold text-danger">
                        To reset your login PIN, please enter the OTP sent to
                        your registered contact details.
                      </p>
                      <input
                        type="text"
                        className="form-control"
                        id="otp"
                        name="otp"
                        placeholder="Enter OTP"
                        value={formData.otp}
                        onChange={handleInputChange}
                      />
                    </div>
                  ) : buttonState === "resetPin" && customers.length > 0 ? (
                    <>
                      <h6 className="text-primary text-center">
                        Select Customer To Reset Pin
                      </h6>
                      <div className="form-group text-center">
                        {customers.map((customer, index) => (
                          <button
                            type="button"
                            className="btn mb-4 text-light"
                            key={index}
                            onClick={() =>
                              handleResetPin(
                                customer.customer_id,
                                customer.mobile_number
                              )
                            }
                            style={{ background: "#00172d" }}
                          >
                            {customer.customer_name} - {customer.customer_id}
                          </button>
                        ))}
                      </div>
                    </>
                  ) : null}
                  <div className="actions mb-4 align-left">
                    {buttonState === "forgotPin" ? (
                      <input
                        type="submit"
                        className="btn btn-primary"
                        value="Get OTP"
                        onClick={handleSubmitMobileNumber}
                        style={{ background: "#00172d", fontSize: "12px" }}
                      />
                    ) : buttonState === "otp" ? (
                      <>
                        <input
                          type="submit"
                          className="btn btn-primary"
                          value="Submit"
                          onClick={handleSubmitOtp}
                          style={{ background: "#00172d", fontSize: "12px" }}
                        />
                        <Link
                          className="link text-primary"
                          to=""
                          onClick={handleSubmitMobileNumber}
                          style={{
                            marginLeft: "40%",
                            fontSize: "14px",
                            fontWeight: "bold",
                            textDecoration: "none",
                          }}
                        >
                          Resend OTP
                        </Link>
                      </>
                    ) : null}
                  </div>
                  <hr />
                  <div className="" style={{ marginLeft: "20%" }}>
                    <Link
                      className="link text-primary"
                      to="/login"
                      style={{
                        marginLeft: "5%",
                        fontSize: "14px",
                        fontWeight: "bold",
                        textDecoration: "none",
                      }}
                    >
                      Go Back to Login?
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <footer className="sticky-footer">
        <div className="footer">
          <div className="copyright text-center my-auto">
            <span className="text-light">
              Copyright &copy; Radiant Cash Management Services Ltd 2024
            </span>
            <span className="float-right text-light">
              <Link className="text-light" to="">
                Privacy and Policy
              </Link>{" "}
              |
              <Link to="" className="text-light">
                {" "}
                Terms and Conditions
              </Link>{" "}
              |
              <Link to="" className="text-light">
                {" "}
                Services with Pricing
              </Link>{" "}
              |
              <Link to="" className="text-light">
                {" "}
                Refund and Cancellation Policy
              </Link>
            </span>
          </div>
        </div>
      </footer>
    </>
  );
};

export default ForgotLoginPinNew;
