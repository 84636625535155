import React, { useCallback, useEffect, useState } from "react";
import axios from "../axios/url";
import EncryptPayload from "../../utils/EncryptPayload";
import DecryptPayload from "../../utils/DecryptPayload";
import Authorization from "../../utils/Authorization";
import UserData from "../../storage/UserData";
import Select from "react-select";

const Shop = () => {
  const user = UserData();

  //** Fetch Shop Data Start **/
  const [shopsData, setShopsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState({});

  const fetchShopData = useCallback(async () => {
    setLoading(true);
    const requestData = {
      type: "FetchCustomerShopRIC",
      customer_id: user.customer_id,
    };

    const requestDataString = JSON.stringify(requestData);

    const EncData = EncryptPayload(requestDataString);
    const PayloadEnc = EncData[0].payload.toString();
    const IvEnc = EncData[0].iv.toString();

    try {
      const checkResponse = await axios.post("/custShopRIC.php", PayloadEnc, {
        headers: {
          "Content-Type": "text/plain",
          Authorization: Authorization,
          EncAuth: IvEnc,
        },
      });

      const encAuthHeaderValue = checkResponse.headers["encauth"];
      const decryptedData = DecryptPayload(
        checkResponse.data,
        encAuthHeaderValue
      );

      const parsedData = JSON.parse(decryptedData);
      if (parsedData.status === "success") {
        setShopsData(parsedData.data);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  }, [user?.customer_id]);
  //** Fetch Shop Data End **/

  useEffect(() => {
    if (user) {
      fetchShopData();
      setFormData((prevData) => ({
        ...prevData,
        customerID: user.customer_id || "",
      }));
    }
  }, [user, fetchShopData]);

  //** Location Fetch Start **/

  const [locationData, setLocationData] = useState({
    state: "",
    district: "",
    locations: [],
  });
  const [selectedOption, setSelectedOption] = useState(null);

  const options = locationData.locations.map((loc) => ({
    value: loc.location,
    label: loc.location,
    loc_id: loc.loc_id,
  }));

  //Location Select Options
  const handleSelectChange = (option) => {
    setSelectedOption(option);
    setFormData({
      ...formData,
      location: option ? option.value : "",
      locationID: option ? option.loc_id : "",
    });
    errors.location = "";
  };

  //Location Fetch API Call
  const handleLocation = async () => {
    const { pincode } = formData;

    if (pincode) {
      const requestData = {
        type: "FetchShopLocation",
        pincode: formData.pincode,
      };

      const requestDataString = JSON.stringify(requestData);

      const EncData = EncryptPayload(requestDataString);
      const PayloadEnc = EncData[0].payload.toString();
      const IvEnc = EncData[0].iv.toString();

      try {
        const checkResponse = await axios.post(
          "/custLocationRIC.php",
          PayloadEnc,
          {
            headers: {
              "Content-Type": "text/plain",
              Authorization: Authorization,
              EncAuth: IvEnc,
            },
          }
        );

        const encAuthHeaderValue = checkResponse.headers["encauth"];
        const decryptedData = DecryptPayload(
          checkResponse.data,
          encAuthHeaderValue
        );

        const parsedData = JSON.parse(decryptedData);

        if (parsedData.status === "success") {
          const { state, district, location } = parsedData.data[0];
          setSelectedOption(null);
          setLocationData({
            state,
            district,
            locations: location,
          });
          setFormData((prevData) => ({
            ...prevData,
            state: state || "",
            district: district || "",
          }));
          errors.pincode = "";
        } else if (parsedData.status === "error") {
          alert(parsedData.message);
          setFormData({
            pincode: "",
            state: "",
            district: "",
            location: "",
            doorNo: "",
            streetAddress1: "",
            streetAddress2: "",
            landmark: "",
            contactName: "",
            primaryMobile: "",
            secondaryMobile: "",
          });
          setSelectedOption(null);
          setLocationData({
            state: "",
            district: "",
            locations: [],
          });
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  //** Location Fetch End **/

  //** Add Shop Data Start **/

  const [formData, setFormData] = useState({
    customerID: user ? user.customer_id : "",
    pincode: "",
    state: "",
    district: "",
    location: "",
    locationID: "",
    doorNo: "",
    streetAddress1: "",
    streetAddress2: "",
    landmark: "",
    contactName: "",
    primaryMobile: "",
    secondaryMobile: "",
  });

  const validateField = (name, value, newErrors) => {
    const trimmedValue = value.trim();

    const numericRegex = /^[0-9]*$/;
    const alphaRegex = /^[a-zA-Z]+$/;

    if (name === "pincode") {
      if (!trimmedValue) {
        newErrors.pincode = "Pincode is required";
      } else {
        delete newErrors.pincode;
      }
    }
    if (name === "location") {
      if (!trimmedValue) {
        newErrors.location = "Location is required";
      } else {
        delete newErrors.location;
      }
    }
    if (name === "doorNo") {
      if (!trimmedValue) {
        newErrors.doorNo = "Door No is required";
      } else {
        delete newErrors.doorNo;
      }
    }
    if (name === "streetAddress1") {
      if (!trimmedValue) {
        newErrors.streetAddress1 = "Street Address 1 is required";
      } else {
        delete newErrors.streetAddress1;
      }
    }
    if (name === "streetAddress2") {
      if (!trimmedValue) {
        newErrors.streetAddress2 = "Street Address 2 is required";
      } else {
        delete newErrors.streetAddress2;
      }
    }
    if (name === "landmark") {
      if (!trimmedValue) {
        newErrors.landmark = "Landmark is required";
      } else {
        delete newErrors.landmark;
      }
    }
    if (name === "contactName") {
      if (!trimmedValue) {
        newErrors.contactName = "Shop Contact Name is required";
      } else if (!alphaRegex.test(trimmedValue)) {
        newErrors.contactName =
          "Shop Contact Name should contain only alphabets";
      } else {
        delete newErrors.contactName;
      }
    }
    if (name === "primaryMobile") {
      if (!trimmedValue) {
        newErrors.primaryMobile = "Primary Mobile Number is required";
      } else if (!numericRegex.test(trimmedValue)) {
        newErrors.primaryMobile = "Primary Mobile Number should be in numbers";
      } else if (trimmedValue.length !== 10) {
        newErrors.primaryMobile = "Primary Mobile Number should be 10 digits";
      } else {
        delete newErrors.primaryMobile;
      }
    }
    if (name === "secondaryMobile") {
      if (!trimmedValue) {
        delete newErrors.secondaryMobile;
      } else if (trimmedValue.length !== 10) {
        newErrors.secondaryMobile =
          "Secondary Mobile Number should be 10 digits";
      } else {
        delete newErrors.secondaryMobile;
      }
    }
  };

  const validateForm = (formData) => {
    const newErrors = {};
    for (const field in formData) {
      validateField(field, formData[field], newErrors);
    }
    return newErrors;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    let processedValue = value;

    if (name === "pincode") {
      if (/^[0-9]*$/.test(value)) {
        processedValue = value.slice(0, 6);
      } else {
        return;
      }
    }

    if (name === "primaryMobile" || name === "secondaryMobile") {
      if (/^[0-9]*$/.test(value)) {
        processedValue = value.slice(0, 10);
      } else {
        return;
      }
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: processedValue,
    }));

    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      validateField(name, processedValue, newErrors);
      return newErrors;
    });
  };

  //API call for submitting shop data
  const handleSubmitShop = async (e) => {
    e.preventDefault();

    const newErrors = validateForm(formData);

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    const requestData = {
      type: "AddCustomerShopRIC",
      customer_id: user ? user.customer_id : "",
      pincode: formData.pincode,
      state: formData.state,
      district: formData.district,
      pickup_location: formData.location,
      location_id: formData.locationID,
      door_no: formData.doorNo,
      street_address_1: formData.streetAddress1,
      street_address_2: formData.streetAddress2,
      landmark: formData.landmark,
      contact_name: formData.contactName,
      contact_number: formData.primaryMobile,
      secondary_number: formData.secondaryMobile,
    };

    const requestDataString = JSON.stringify(requestData);

    const EncData = EncryptPayload(requestDataString);
    const PayloadEnc = EncData[0].payload.toString();
    const IvEnc = EncData[0].iv.toString();

    try {
      const checkResponse = await axios.post("/custShopRIC.php", PayloadEnc, {
        headers: {
          "Content-Type": "text/plain",
          Authorization: Authorization,
          EncAuth: IvEnc,
        },
      });

      const encAuthHeaderValue = checkResponse.headers["encauth"];
      const decryptedData = DecryptPayload(
        checkResponse.data,
        encAuthHeaderValue
      );

      const parsedData = JSON.parse(decryptedData);

      if (parsedData.status === "success") {
        alert(parsedData.message);
        setFormData({
          customerID: user ? user.customer_id : "",
          pincode: "",
          state: "",
          district: "",
          location: "",
          doorNo: "",
          streetAddress1: "",
          streetAddress2: "",
          landmark: "",
          contactName: "",
          primaryMobile: "",
          secondaryMobile: "",
        });
        setSelectedOption(null);
        setLocationData({
          state: "",
          district: "",
          locations: [],
        });
        fetchShopData();
      } else if (parsedData.status === "error") {
        alert(parsedData.message);
        return;
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  //** Add Shop Data End **/

  // useEffect(() => {
  //   if (shopsData.length > 0) {
  //     new DataTable("#dataTable");
  //   }
  // }, [shopsData]);

  return (
    <div className="container-fluid">
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 className="h3 mb-0 text-gray-900">Shop</h1>
      </div>
      <div className="card shadow mb-4">
        <div className="card-header py-3 d-sm-flex align-items-center justify-content-between mb-4">
          <h6 className="m-0 font-weight-bold text-primary">Add Shop</h6>
        </div>
        <div className="card-body ml-4">
          <form action="" autoComplete="off">
            <div className="form-row">
              <div className="form-group col-md-3">
                <label className="text-gray-900">
                  Pincode <span className="text-lg text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control form-user mb-2"
                  name="pincode"
                  value={formData.pincode}
                  onChange={handleInputChange}
                  // onChange={(e) =>
                  //   setFormData({ ...formData, pincode: e.target.value })
                  // }
                  onBlur={handleLocation}
                />
                {errors.pincode && (
                  <span className="font-weight-bold text-danger">
                    {errors.pincode}
                  </span>
                )}
              </div>
              <div className="form-group col-md-3">
                <label className="text-gray-900">
                  State <span className="text-lg text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control form-control-user"
                  name="state"
                  value={formData.state}
                  readOnly
                />
              </div>
              <div className="form-group col-md-3">
                <label className="text-gray-900">
                  District <span className="text-lg text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control form-control-user"
                  name="district"
                  value={formData.district}
                  readOnly
                />
              </div>
              <div className="form-group col-md-3">
                <label className="text-gray-900">
                  Pickup Location <span className="text-lg text-danger">*</span>
                </label>
                <Select
                  value={selectedOption}
                  onChange={handleSelectChange}
                  options={options}
                  placeholder="Select Pickup Location"
                  className="mb-2"
                />
                {errors.location && (
                  <span className="font-weight-bold text-danger">
                    {errors.location}
                  </span>
                )}
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-3">
                <label className="text-gray-900">
                  Door No <span className="text-lg text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control form-control-user mb-2"
                  name="doorNo"
                  value={formData.doorNo}
                  onChange={handleInputChange}
                />
                {errors.doorNo && (
                  <span className="font-weight-bold text-danger">
                    {errors.doorNo}
                  </span>
                )}
              </div>
              <div className="form-group col-md-3">
                <label className="text-gray-900">
                  Street Address 1{" "}
                  <span className="text-lg text-danger">*</span>
                </label>
                <textarea
                  className="form-control form-control-user mb-2"
                  name="streetAddress1"
                  value={formData.streetAddress1}
                  onChange={handleInputChange}
                />
                {errors.streetAddress1 && (
                  <span className="font-weight-bold text-danger">
                    {errors.streetAddress1}
                  </span>
                )}
              </div>
              <div className="form-group col-md-3">
                <label className="text-gray-900">
                  Street Address 2{" "}
                  <span className="text-lg text-danger">*</span>
                </label>
                <textarea
                  className="form-control form-control-user mb-2"
                  name="streetAddress2"
                  value={formData.streetAddress2}
                  onChange={handleInputChange}
                />
                {errors.streetAddress2 && (
                  <span className="font-weight-bold text-danger">
                    {errors.streetAddress2}
                  </span>
                )}
              </div>
              <div className="form-group col-md-3">
                <label className="text-gray-900">
                  Landmark <span className="text-lg text-danger">*</span>
                </label>
                <textarea
                  className="form-control form-control-user mb-2"
                  name="landmark"
                  value={formData.landmark}
                  onChange={handleInputChange}
                />
                {errors.landmark && (
                  <span className="font-weight-bold text-danger">
                    {errors.landmark}
                  </span>
                )}
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-3">
                <label className="text-gray-900">
                  Shop Contact Name{" "}
                  <span className="text-lg text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control form-control-user mb-2"
                  name="contactName"
                  value={formData.contactName}
                  onChange={handleInputChange}
                />
                {errors.contactName && (
                  <span className="font-weight-bold text-danger">
                    {errors.contactName}
                  </span>
                )}
              </div>
              <div className="form-group col-md-3">
                <label className="text-gray-900">
                  Primary Mobile Number{" "}
                  <span className="text-lg text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control form-control-user mb-2"
                  name="primaryMobile"
                  value={formData.primaryMobile}
                  onChange={handleInputChange}
                />
                {errors.primaryMobile && (
                  <span className="font-weight-bold text-danger">
                    {errors.primaryMobile}
                  </span>
                )}
              </div>
              <div className="form-group col-md-3">
                <label className="text-gray-900">
                  Secondary Mobile Number{" "}
                  <span className="text-lg text-danger"></span>
                </label>
                <input
                  type="text"
                  className="form-control form-control-user mb-2"
                  name="secondaryMobile"
                  value={formData.secondaryMobile}
                  onChange={handleInputChange}
                />
                {errors.secondaryMobile && (
                  <span className="font-weight-bold text-danger">
                    {errors.secondaryMobile}
                  </span>
                )}
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-3">
                <button
                  type="button"
                  className="btn btn-primary btn-sm"
                  onClick={handleSubmitShop}
                >
                  Add Shop
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="card shadow mb-4">
        <div className="card-header py-3 d-sm-flex align-items-center justify-content-between mb-4">
          <h6 className="m-0 font-weight-bold text-primary">View Shop</h6>
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <table className="table table-bordered" id="dataTable" width="100%">
              <thead className="text-light">
                <tr className="bg-gradient-primary">
                  <th>S.No</th>
                  <th>Shop ID</th>
                  <th>Pincode</th>
                  <th>State</th>
                  <th>District</th>
                  <th>Location</th>
                  <th>Address</th>
                  <th>Subscription</th>
                  <th>Subscription Expire Date</th>
                  <th>Subscription Days Remaining</th>
                  <th>Approval Status</th>
                </tr>
              </thead>
              <tbody className="text-gray-900">
                {loading ? (
                  <tr>
                    <td>
                      <img src="/img/loading.gif" alt="Loading..." />
                    </td>
                  </tr>
                ) : shopsData.length > 0 ? (
                  shopsData.map((shopData, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{shopData.point_id}</td>
                      <td>{shopData.pincode}</td>
                      <td>{shopData.state}</td>
                      <td>{shopData.district}</td>
                      <td>{shopData.location}</td>
                      <td>{shopData.address}</td>
                      <td>{shopData.subscription}</td>
                      <td>{shopData.subscription_expire_date}</td>
                      <td>{shopData.subscription_days_left}</td>
                      <td>{shopData.shop_approve_status}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td>No Records Found</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Shop;
